import { Outlet } from 'react-router-dom';

const Captured = () => {


	return (
		<div className="breweries">
			<h1>Captured, Thank you</h1>
			<br />
			<span>
				<a href="/view">View Data</a>
			</span>
			<Outlet />
		</div>	
	) 
}

export default Captured;
