import React, { useState } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';

import axios from 'axios';
import { format } from "date-fns";
import { getUser , getToken } from "../../../service/AuthService";

let getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker';

function MedicinesData() {

    const [timeOfDay, setTimeOfDay] = useState([]);
    const [datetime, setDateTime] = useState([]);
    const [quantity, setQuantity] = useState([]);
    const [medicine, setMedicine] = useState([]);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
    const user = getUser();
    const token = getToken();

    const requestParams = {
      activityType: 'Medicine',
      'timeOfDay': '',
      'datetime': '',
      'quantity': '',
      'medicine': '',
      'notes': '',
      'userName': user.userName
    };;

    const requestBody = () => {
      requestParams['timeOfDay'] = timeOfDay;
      requestParams['datetime'] = datetime;
      requestParams['medicine'] = medicine;
      requestParams['quantity'] = quantity;
      requestParams['notes'] = notes;
      requestParams['userName'] = user.username;
      return requestParams;
    }

    // console.log(requestBody());
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      let paramsbody = requestBody();
      console.log("Posting : " + JSON.stringify(paramsbody));
      await axios.post(
        getURL, null,
        {params: paramsbody}
      ).then(response => {
        navigate('/thanks');
      }).catch(function (error) {
        console.log(error);
      });
    };

    var date = new Date();
    var formattedDate = format(date, "MM/dd/yyyy, H:mm:ss");

    if (token) {
      return (
        <div id="breweries">
          <form onSubmit={(e)=>handleSubmit(e)}>
          <div>
          <h1>Pick a Date Time</h1>
              <input  
                  id="datetime-local"
                  name="datetime"
                  default={formattedDate}
                  type="datetime-local"
                  format="MM/dd/yyyy HH:MI:SS"
                  onChange={e => setDateTime(e.target.value)}
                />
          </div>
          <div>
            <h1>Time of Day</h1>
            <h3>
              <input 
                type="radio" 
                value="Morning" 
                name="timeOfDay" 
                onChange={e => setTimeOfDay(e.target.value)}/> Morning{'\u00A0'}
              <input 
                type="radio" 
                value="Evening" 
                name="timeOfDay" 
                onChange={e => setTimeOfDay(e.target.value)}/> Evening{'\u00A0'}
              <input 
                type="radio" 
                value="Night" 
                name="timeOfDay" 
                onChange={e => setTimeOfDay(e.target.value)}/> Night
            </h3>
          </div>
          <div >
            <h1>Quantity</h1>
            <h3>
              <input 
                type="radio" 
                value="1" 
                name="quantity" 
                onChange={e => setQuantity(e.target.value)}/> 1{'\u00A0'}
              <input 
                type="radio" 
                value="2" 
                name="quantity" 
                onChange={e => setQuantity(e.target.value)}/> 2{'\u00A0'}
              <input 
                type="radio" 
                value="3" 
                name="quantity" 
                onChange={e => setQuantity(e.target.value)}/> 3{'\u00A0'}
              <input 
                type="radio" 
                value="4" 
                name="quantity" 
                onChange={e => setQuantity(e.target.value)}/> 4
            </h3>
          </div>
          <div>
            <h1>Medicine:</h1>
            <select name="medicine" 
                    onChange={e => setMedicine(e.target.value)}>
              <option value=""></option>
              <option value="Pentasa">Pentasa</option>
              <option value="Pasupu">Pasupu</option>
              <option value="Iron">Iron</option>
              <option value="Homeo">Homeo</option>
              <option value="Homeo Powder">Homeo Powder</option>
              <option value="B12">B12</option>
              <option value="MultiVitamin">Multi Vitamin</option>
              <option value="Ashwaganda">Ashwaganda</option>
              <option value="Vitamin C">Vitamin C</option>
              <option value="Ashwaganda">Ashwaganda</option>
              <option value="Akkermansia">Akkermansia</option>
            </select>
          </div>
          <div>
            <h1>Notes</h1>
            <input type='text' name="notes"
              rows={5}
              cols={5}
              onChange={e => setNotes(e.target.value)}/>
          </div>
          <div>
            <button className="buttonW" type="submit" size="lg">Send</button>
          </div>
          </form>
          <Outlet />
        </div>
      );
    } else {
        return (
          <div>
            {/* <img alt="Home" src={defaultprofilepic} /> */}
            <br />
            <h1>
              Please Sign In and try again
            </h1>
            <span>
              <a href="/login">Sign In</a>
            </span>
            <Outlet />
          </div>
        );
    }
}

export default MedicinesData;