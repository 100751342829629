import {  useEffect } from "react";
import { Outlet } from 'react-router-dom';

import { getUser, getToken, setUserSession, resetUserSession} from "../service/AuthService";
import axios from '../api/axios';

const ViewData = () => {
	const verifyTokenAPIURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/verify';
	const user = getUser();
	const token = getToken();

	useEffect(() => {
	
		const token = getToken();
		if (token === 'undefined' || token === undefined || token === null || !token) {
		  return;
		}
	    
		const requestConfig = {
		  headers: {
		    'x-api-key': 'OkhIJdHFMomDeRVUXGfa1EXWiGBAWpdakg7ZRCFf'
		  }
		}
		const requestBody = {
		  user: getUser(),
		  token: token
		}
		axios.post(verifyTokenAPIURL, requestBody, requestConfig).then(response => {
		  setUserSession(response.data.user, response.data.token);
		}).catch(() => {
		  resetUserSession();
		})
	    }, []);

	if (token && user) {
		return (
			<section>
				<div align="center">
					<h1>View</h1>
				</div>
				<div className="breweries">
					<a href="/view/medicines">Medicines</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<a href="/view/bowel">Motion</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<a href="/view/food">Nutrition</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<a href="/view/sleep">Sleep</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<a href="/view/excercise">Excercise</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<a href="/view/stats">Stats</a>
					<Outlet />
				</div>
			</section>	
		)
	} else {

		return (
		
			<div>
				<br />
				<h1>
					Try Again later.
				</h1>
				<Outlet />
			</div>
		);
	}
}

export default ViewData;
