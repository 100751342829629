import React from "react";
import { Routes, Route } from "react-router-dom";

// import { getToken } from "../service/AuthService";
import Home from '../pages/Home';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Capture from '../pages/Capture';
import Display from '../pages/View';

import Medicines from '../components/capture/Medicines';
import Motions from '../components/capture/Motions';
import Food from '../components/capture/Food';
import Sleep from '../components/capture/Sleep';
import Excercise from '../components/capture/Excercise';
import Stats from '../components/capture/Stats';

import Captured from '../components/captured';

import MedicinesDisplay from '../components/display/Medicines';
import MotionsDisplay from '../components/display/Motions';
import FoodDisplay from '../components/display/Food';
import SleepDisplay from '../components/display/Sleep';
import ExcerciseDisplay from '../components/display/Excercise';
import StatsDisplay from '../components/display/Stats';

export default function MyRoutes() {
//   const token  = getToken();

    return (
	<Routes>
		<Route path="/capture" element={<Capture />}>
			<Route path="medicines" element={<Medicines />} />
			<Route path="bowel" element={<Motions />} />
			<Route path="food" element={<Food />} />
			<Route path="sleep" element={<Sleep />} />
			<Route path="excercise" element={<Excercise />} />
			<Route path="stats" element={<Stats />} />
		</Route>
		<Route path="/view" element={<Display />}>
			<Route path="medicines" element={<MedicinesDisplay />} />
			<Route path="bowel" element={<MotionsDisplay />} />
			<Route path="food" element={<FoodDisplay />} />
			<Route path="sleep" element={<SleepDisplay />} />
			<Route path="excercise" element={<ExcerciseDisplay />} />
			<Route path="stats" element={<StatsDisplay />} />
		</Route>
		<Route path="/register" element={<Register />} />
		<Route path="/thanks" element={<Captured />} />
		<Route path="/login" element={<Login />} />
		<Route path="/" element={<Home />} />
	</Routes>
    );
  
}

