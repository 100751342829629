import {  useEffect } from "react";

import { getUser, getToken, setUserSession, resetUserSession} from "../service/AuthService";
import axios from '../api/axios';


const Home = () => {
	const verifyTokenAPIURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/verify';
	const user = getUser();

	useEffect(() => {
	
		const token = getToken();
		if (token === 'undefined' || token === undefined || token === null || !token) {
		  return;
		}
	    
		const requestConfig = {
		  headers: {
		    'x-api-key': 'OkhIJdHFMomDeRVUXGfa1EXWiGBAWpdakg7ZRCFf'
		  }
		}
		const requestBody = {
		  user: getUser(),
		  token: token
		}
		axios.post(verifyTokenAPIURL, requestBody, requestConfig).then(response => {
		  setUserSession(response.data.user, response.data.token);
		}).catch(() => {
		  resetUserSession();
		})
	    }, []);

	const token = getToken();

	if (token && user) {
		return (
			<div className="breweries">
				<h1>
					Welcome
				</h1>
			</div>	
		)
	} else {

		return (
		
			<div>
				<br />
				<h1>
					Welcome
				</h1>
				<span>
					<a href="/login">Sign In</a>
				</span>
			</div>
		);
	}
}

export default Home;
