import React from 'react';
import Header from './Header';
// import Navigation from './Navbar';
const Layout = ({ children }) => {
    return (
    <React.Fragment>
        <div>
            <Header />
            {/* <Navigation /> */}
            {children}
        </div>
    </React.Fragment>
    );
};
export default Layout;