import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import '../../../App.css';
import axios from 'axios';
import { getUser } from "../../../service/AuthService";
 
function MotionsDisplay() {

    const [data, getData] = useState([])
    let getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker?activity=Motions';

    useEffect(() => {
        const user = getUser();
        if(user){
            getURL=getURL+"&userName="+user.username;
        }
         axios.get(getURL).then(function (res) {
            // handle success
            console.log(res.data);
            getData(res.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
    }, [])
 
 
    return (
        <>
         <h1>Basis Stats captures in past one Day</h1>
          <table>
            <tbody>
                <tr>
                    <th>Activity&nbsp;&nbsp;&nbsp;</th>
                    <th>Bloody&nbsp;&nbsp;&nbsp;</th>
                    <th>Formed&nbsp;&nbsp;&nbsp;</th>
                    <th>Time Of Day&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>Date&nbsp;&nbsp;&nbsp;</th>
                </tr>
                {data.map((item, i) => (
                    <tr key={i}>
                        <td>{item.data.activityType}&nbsp;&nbsp;&nbsp;</td>
                        <td>{item.data.bloody}&nbsp;&nbsp;&nbsp;</td>
                        <td>{item.data.formed}&nbsp;&nbsp;&nbsp;</td>
                        <td>{item.data.timeOfDay}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td>{item.data.datetime}&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                ))}
            </tbody>
            <Outlet />
          </table>
        </>
    );
}
 
export default MotionsDisplay;