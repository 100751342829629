import React, { useState } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';
import { format } from "date-fns";

import axios from 'axios';
import { getUser , getToken } from "../../../service/AuthService";

let getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker';

export default function FoodData() {

    const [timeOfDay, setTimeOfDay] = useState([]);
    const [datetime, setDateTime] = useState([]);
    const [stat, setStat] = useState([]);
    const [value, setValue] = useState([]);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
    const user = getUser();
    const token = getToken();

    const requestParams = {
      activityType: 'Stats',
      'stat': '',
      'datetime': '',
      'timeOfDay': '',
      'value': '',
      'notes': '',
      'userName': ''
    };;

    const requestBody = () => {
      requestParams['timeOfDay'] = timeOfDay;
      requestParams['datetime'] = datetime;
      requestParams['stat'] = stat;
      requestParams['value'] = value;
      requestParams['notes'] = notes;
      requestParams['userName'] = user.username;
      return requestParams;
    }

    // console.log(requestBody());
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      let paramsbody = requestBody();
      console.log("Posting : " + JSON.stringify(paramsbody));
      await axios.post(
        getURL, null,
        {params: paramsbody}
      ).then(response => {
        navigate('/thanks');
      }).catch(function (error) {
        console.log(error);
      });
    };

    var date = new Date();
    var formattedDate = format(date, "MM/dd/yyyy, H:mm:ss");

    if (token) {
      return (
        <div >
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div>
            <h1>Pick a Date Time</h1>
              <input
                  id="datetime-local"
                  name="datetime"
                  default={formattedDate}
                  type="datetime-local"
                  format="MM/dd/yyyy HH:MI:SS"
                  onChange={e => setDateTime(e.target.value)}
                />
            </div>
            <div >
            <h1>Stat</h1>
              <select name="stat"  
                    onChange={e => setStat(e.target.value)} >
                <option value=""></option>
                <option value="Weight">Weight</option>
                <option value="Height">Height</option>
              </select>
            </div>
            <div >
              <h1>Time Of Day</h1>
              <h3>
                <input type="radio" value="AM" name="timeOfDay" 
                      onChange={e => setTimeOfDay(e.target.value)}/>AM
                <input type="radio" value="PM" name="timeOfDay" 
                      onChange={e => setTimeOfDay(e.target.value)}/>PM
              </h3>
            </div>
            <div>
              <h1>Value (Decimal)</h1>
              <input type="text" 
                name="value"
                onChange={e => setValue(e.target.value)}
              />
            </div>
            <div>
              <h1>Notes</h1>
              <input type='text' name="notes"
                rows={5}
                cols={5}
                onChange={e => setNotes(e.target.value)}/>
            </div>
            <div>
              <button type="submit">Send</button>
            </div>
          </form>
          <Outlet />
        </div>
      );
    } else {
        return (
          <div>
            {/* <img alt="Home" src={defaultprofilepic} /> */}
            <br />
            <h1>
              Please Sign In and try again
            </h1>
            <span>
              <a href="/login">Sign In</a>
            </span>
            <Outlet />
          </div>
        );
    }
}

