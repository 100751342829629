import React from 'react';
import { useNavigate , Link } from 'react-router-dom';

import { getUser , getToken , resetUserSession } from "../service/AuthService";

const Navigation = () => {

    const navigate = useNavigate();
    const token = getToken();
    const user = getUser();

	const handleLogout = async (e) => {
		resetUserSession();
		navigate('/');
	}

    if(token && user) {
        return (
            <div>
            <Link to="/">Home</Link>&nbsp;&nbsp;
            <Link to="/capture">Capture</Link>&nbsp;&nbsp;
            <Link to="/view">View</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleLogout}>
                Logout
            </button>
            </div>
        );
    } else {
        return (
            <div>
            <Link to="/">Home</Link>&nbsp;&nbsp;
            <Link to="/capture">Capture</Link>&nbsp;&nbsp;
            <Link to="/view">View</Link>
           
            </div>
        );
    }
};
export default Navigation;