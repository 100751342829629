import React, { useState } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';
import { format } from "date-fns";

import axios from 'axios';
import { getUser , getToken } from "../../../service/AuthService";

let getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker';

export default function FoodData() {

    const [timeOfDay, setTimeOfDay] = useState([]);
    const [datetime, setDateTime] = useState([]);
    const [quantity, setQuantity] = useState([]);
    const [quality, setQuality] = useState([]);
    const [improved, setImproved] = useState([]);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
    const user = getUser();
    const token = getToken();

    const requestParams = {
      activityType: 'Sleep',
      'timeOfDay': '',
      'datetime': '',
      'quantity': '',
      'quality': '',
      'improved': '',
      'notes': '',
      'userName': ''
    };;

    const requestBody = () => {
      requestParams['timeOfDay'] = timeOfDay;
      requestParams['datetime'] = datetime;
      requestParams['improved'] = improved;
      requestParams['quantity'] = quantity;
      requestParams['quality'] = quality;
      requestParams['notes'] = notes;
      requestParams['userName'] = user.username;
      return requestParams;
    }

    // console.log(requestBody());
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      let paramsbody = requestBody();
      console.log("Posting : " + JSON.stringify(paramsbody));
      await axios.post(
        getURL, null,
        {params: paramsbody}
      ).then(response => {
        navigate('/thanks');
      }).catch(function (error) {
        console.log(error);
      });
    };

    var date = new Date();
    var formattedDate = format(date, "MM/dd/yyyy, H:mm:ss");

    if (token) {
      return (
        <div >
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div>
            <h1>Pick a Date Time</h1>
            <input
                id="datetime-local"
                name="datetime"
                default={formattedDate}
                type="datetime-local"
                format="MM/dd/yyyy HH:MI:SS"
                onChange={e => setDateTime(e.target.value)}
              />
            </div>
            <div>
              <h1>Time of the Day</h1>
              <h3>
                <input type="radio" value="Morning" name="timeofDay" 
                        onChange={e => setTimeOfDay(e.target.value)}/> Morning{'\u00A0'}
                <input type="radio" value="Afternoon" name="timeofDay" 
                        onChange={e => setTimeOfDay(e.target.value)}/> Afternoon{'\u00A0'}
                <input type="radio" value="Evening" name="timeofDay" 
                        onChange={e => setTimeOfDay(e.target.value)}/> Evening{'\u00A0'}
                <input type="radio" value="Night" name="timeofDay" 
                        onChange={e => setTimeOfDay(e.target.value)}/> Night
              </h3>
              <h1>Quality</h1>
              <h3>
                <input 
                  type="radio" 
                  value="Yes" 
                  name="quality" 
                  onChange={e => setQuality(e.target.value)}/> Yes{'\u00A0'}
                <input 
                  type="radio" 
                  value="No" 
                  name="quality" 
                  onChange={e => setQuality(e.target.value)}/> No
              </h3>
            </div>
            <div>
              <h1>Improved</h1>
              <h3>
                  <input 
                    type="radio" 
                    value="Yes" 
                    name="improved" 
                    onChange={e => setImproved(e.target.value)}/> Yes{'\u00A0'}
                  <input 
                    type="radio" 
                    value="No" 
                    name="improved" 
                    onChange={e => setImproved(e.target.value)}/> No
              </h3>
            </div>
              <div>
                <h1>Time in Hours:</h1>
                <select name="quantity"
                        onChange={e => setQuantity(e.target.value)}>
                  <option value=""></option>
                  <option value="0">None</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div>
                <h1>Notes</h1>
                <input type='text' name="notes"
                  rows={5}
                  cols={5}
                  onChange={e => setNotes(e.target.value)}/>
              </div>
              <div>
                <div><button type="submit">Send</button></div>
              </div>
          </form>
          <Outlet />
      </div>
      );
    } else {
        return (
          <div>
            {/* <img alt="Home" src={defaultprofilepic} /> */}
            <br />
            <h1>
              Please Sign In and try again
            </h1>
            <span>
              <a href="/login">Sign In</a>
            </span>
            <Outlet />
          </div>
        );
    }
}

