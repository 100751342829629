import React, { useState } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';

import axios from 'axios';
import { format } from "date-fns";
import { getUser , getToken } from "../../../service/AuthService";

function MotionsData() {

    let getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker';

    const [timeOfDay, setTimeOfDay] = useState([]);
    const [datetime, setDateTime] = useState([]);
    const [formed, setFormed] = useState([]);
    const [bloody, setBloody] = useState([]);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
    const user = getUser();
    const token = getToken();

    const requestParams = {
      activityType: 'Motions',
      timeOfDay: '',
      datetime: '',
      formed: '',
      bloody: '',
      notes: '',
      'userName': user.userName
    };;

    const requestBody = () => {
      requestParams['timeOfDay'] = timeOfDay;
      requestParams['datetime'] = datetime;
      requestParams['formed'] = formed;
      requestParams['bloody'] = bloody;
      requestParams['notes'] = notes;
      requestParams['userName'] = user.username;

      return requestParams;
    }
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      let paramsbody = requestBody();
      console.log("Posting : " + JSON.stringify(paramsbody));
      await axios.post(
        getURL, null,
        {params: paramsbody}
      ).then(response => {
        navigate('/thanks');
      }).catch(function (error) {
        console.log(error);
      });
    };

    var date = new Date();
    var formattedDate = format(date, "MM/dd/yyyy, H:mm:ss");

    if (token) {
      return (
        <div >
            <form onSubmit={(e)=>handleSubmit(e)}>
              <div>
                <h1>Pick a Date Time</h1>
                <input
                    id="datetime-local"
                    name="datetime"
                    type="datetime-local"
                    default={formattedDate}
                    format="MM/dd/yyyy HH:MI:SS"
                    onChange={e => setDateTime(e.target.value)}
                  />
              </div>
              <div>
                <h1>Time of the Day</h1>
                <h3>
                  <input  type="radio" 
                          value="Morning" 
                          name="timeOfDay" 
                          onChange={e => setTimeOfDay(e.target.value)}/> Morning{'\u00A0'}
                  <input  type="radio" 
                          value="Afternoon" 
                          name="timeOfDay" 
                          onChange={e => setTimeOfDay(e.target.value)}/>  Afternoon{'\u00A0'}
                  <input  type="radio" 
                          value="Evening" 
                          name="timeOfDay" 
                          onChange={e => setTimeOfDay(e.target.value)}/> Evening{'\u00A0'}
                  <input  type="radio" 
                          value="Night" 
                          name="timeOfDay" 
                          onChange={e => setTimeOfDay(e.target.value)}/> Night{'\u00A0'}
                  <input  type="radio" 
                          value="MiddleOfNight" 
                          name="timeOfDay" 
                          onChange={e => setTimeOfDay(e.target.value)}/> Middle of Night{'\u00A0'}
                  <input  type="radio" 
                          value="Accident" 
                          name="timeOfDay" 
                          onChange={e => setTimeOfDay(e.target.value)}/> Oopsie
                </h3>
              </div>
              <div>
                <h1>Bloody</h1>
                <h3>
                  <input
                        type="radio" 
                        value="Lot" name="bloody" 
                        onChange={e => setBloody(e.target.value)}/> Lot{'\u00A0'}
                  <input 
                        type="radio" 
                        value="Little" name="bloody" 
                        onChange={e => setBloody(e.target.value)}/> Little{'\u00A0'}
                  <input 
                        type="radio" 
                        value="Wipe" name="bloody" 
                        onChange={e => setBloody(e.target.value)}/> Wipe{'\u00A0'}
                  <input 
                        type="radio" 
                        value="No" name="bloody" 
                        onChange={e => setBloody(e.target.value)}/> No
                </h3>
              </div>
              <div>
                <h1>Form</h1>
                  <h3>
                    <input  type="radio" 
                            value="formed" name="form" 
                            onChange={e => setFormed(e.target.value)}/> Formed{'\u00A0'}
                    
                    <input  type="radio" 
                            value="semi-formed" name="form" 
                            onChange={e => setFormed(e.target.value)}/> Semi-Formed{'\u00A0'}
                    <input  type="radio" 
                            value="loose" name="form" 
                            onChange={e => setFormed(e.target.value)}/> Loose
                  </h3>
              </div>
              <div>
                <h1>Notes</h1>
                <input type="text" name="Notes"
                  rows={5}
                  cols={5}
                  onChange={e => setNotes(e.target.value)} />
              </div>
              <div>
                <button type="submit">Send</button>
              </div>
          </form>
          <Outlet />
        </div>
      );
    } else {
        return (
          <div>
            <br />
            <h1>
              Please Sign In and try again
            </h1>
            <span>
              <a href="/login">Sign In</a>
            </span>
            <Outlet />
          </div>
        );
    }
}

export default MotionsData;