import React, { useState } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';
import { format } from "date-fns";

import axios from 'axios';
import { getUser , getToken } from "../../../service/AuthService";

let getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker';

export default function FoodData() {

    const [timeOfDay, setTimeOfDay] = useState([]);
    const [datetime, setDateTime] = useState([]);
    const [quantity, setQuantity] = useState([]);
    const [food, setFood] = useState([]);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
    const user = getUser();
    const token = getToken();

    const requestParams = {
      activityType: 'Nutrition',
      'timeOfDay': '',
      'datetime': '',
      'quantity': '',
      'food': '',
      'notes': '',
      'userName': ''
    };;

    const requestBody = () => {
      requestParams['timeOfDay'] = timeOfDay;
      requestParams['datetime'] = datetime;
      requestParams['food'] = food;
      requestParams['quantity'] = quantity;
      requestParams['notes'] = notes;
      requestParams['userName'] = user.username;
      return requestParams;
    }

    // console.log(requestBody());
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      let paramsbody = requestBody();
      console.log("Posting : " + JSON.stringify(paramsbody));
      await axios.post(
        getURL, null,
        {params: paramsbody}
      ).then(response => {
        navigate('/thanks');
      }).catch(function (error) {
        console.log(error);
      });
    };

    var date = new Date();
    var formattedDate = format(date, "MM/dd/yyyy, H:mm:ss");

    if (token) {
      return (
        <div >
          <form onSubmit={(e)=>handleSubmit(e)}>
          <div>
            <h1>Pick a Date Time</h1>
            <input
                id="datetime-local"
                name="datetime"
                default={formattedDate}
                type="datetime-local"
                format="MM/dd/yyyy HH:MI:SS"
                onChange={e => setDateTime(e.target.value)}
              />
          </div>
          <div >
              <h1>Time of Day</h1>
              <h3>
                <input 
                  type="radio" 
                  value="Morning" 
                  name="timeOfDay" 
                  onChange={e => setTimeOfDay(e.target.value)}/> Morning{'\u00A0'}
                <input 
                  type="radio" 
                  value="Noon" 
                  name="timeOfDay" 
                  onChange={e => setTimeOfDay(e.target.value)}/> Noon{'\u00A0'}
                <input 
                  type="radio" 
                  value="Evening" 
                  name="timeOfDay" 
                  onChange={e => setTimeOfDay(e.target.value)}/> Evening{'\u00A0'}
                <input 
                  type="radio" 
                  value="Night" 
                  name="timeOfDay" 
                  onChange={e => setTimeOfDay(e.target.value)}/> Night
              </h3>
          </div>
          <div >
              <h1>Quantity</h1>
              <h3>
                <input 
                  type="radio" 
                  value="Good" 
                  name="quantity" 
                  onChange={e => setQuantity(e.target.value)}/> Good{'\u00A0'}
                <input 
                  type="radio" 
                  value="Average" 
                  name="quantity" 
                  onChange={e => setQuantity(e.target.value)}/> Average{'\u00A0'}
                <input 
                  type="radio" 
                  value="Poor" 
                  name="quantity" 
                  onChange={e => setQuantity(e.target.value)}/> Poor{'\u00A0'}
                <input 
                  type="radio" 
                  value="None" 
                  name="quantity" 
                  onChange={e => setQuantity(e.target.value)}/> None
              </h3>
          </div>
          <div>
            <h1>Food</h1>
            <select name="food"
                    onChange={e => setFood(e.target.value)}>
              <option value=""></option>
              <option value="Egg">Egg</option>
              <option value="AM Rice">AM Rice</option>
              <option value="PM Rice">PM Rice</option>
              <option value="AM Kate Farms">AM Kate Farms</option>
              <option value="Noon Kate Farms">Noon Kate Farms</option>
              <option value="Snack Kate Farms">Snack Kate Farms</option>
              <option value="Night Kate Farms">Night Kate Farms</option>
            </select>
          </div>
            <div>
              <h1>Notes</h1>
              <input type="text" name="notes"
                  rows={5}
                  cols={5}
                  onChange={e => setNotes(e.target.value)}/>
            </div>
            <div>
              <button className="button" type="submit" size="lg">Send</button>
            </div>
          </form>
          <Outlet />
        </div>
      );
    } else {
        return (
          <div>
            {/* <img alt="Home" src={defaultprofilepic} /> */}
            <br />
            <h1>
              Please Sign In and try again
            </h1>
            <span>
              <a href="/login">Sign In</a>
            </span>
            <Outlet />
          </div>
        );
    }
}

