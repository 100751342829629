import React, { useState } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';
import { format } from "date-fns";

import axios from 'axios';
import { getUser , getToken } from "../../../service/AuthService";

let getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker';

export default function FoodData() {

    const [timeOfDay, setTimeOfDay] = useState([]);
    const [datetime, setDateTime] = useState([]);
    const [activity, setActivity] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [timeSpent, setTimeSpent] = useState([]);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
    const user = getUser();
    const token = getToken();

    const requestParams = {
      activityType: 'Excercise',
      'timeOfDay': '',
      'datetime': '',
      'activity': '',
      'completed': '',
      'timeSpent': '',
      'notes': '',
      'userName': ''
    };;

    const requestBody = () => {
      requestParams['timeOfDay'] = timeOfDay;
      requestParams['datetime'] = datetime;
      requestParams['activity'] = activity;
      requestParams['completed'] = completed;
      requestParams['timeSpent'] = timeSpent;
      requestParams['notes'] = notes;
      requestParams['userName'] = user.username;
      return requestParams;
    }

    // console.log(requestBody());
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      let paramsbody = requestBody();
      console.log("Posting : " + JSON.stringify(paramsbody));
      await axios.post(
        getURL, null,
        {params: paramsbody}
      ).then(response => {
        navigate('/thanks');
      }).catch(function (error) {
        console.log(error);
      });
    };

    var date = new Date();
    var formattedDate = format(date, "MM/dd/yyyy, H:mm:ss");

    if (token) {
      return (
        <div >
            <form onSubmit={(e)=>handleSubmit(e)}>
            <div>
            <h1>Pick a Date Time</h1>
            <input
                id="datetime-local"
                name="datetime"
                default={formattedDate}
                type="datetime-local"
                format="MM/dd/yyyy HH:MI:SS"
                onChange={e => setDateTime(e.target.value)}
              />
            </div>
              <div>
              <h1>Time of the Day</h1>
              <h3><input type="radio" value="Morning" name="timeofDay" 
                onChange={e => setTimeOfDay(e.target.value)}/> Morning{'\u00A0'}
                <input type="radio" value="Afternoon" name="timeofDay" 
                onChange={e => setTimeOfDay(e.target.value)}/> Afternoon{'\u00A0'}
                <input type="radio" value="Evening" name="timeofDay" 
                onChange={e => setTimeOfDay(e.target.value)}/> Evening</h3>
              </div>
              <div>
                <h1>Time Spent in Minutes</h1>
                <select name="timeSpent"  
                        onChange={e => setTimeSpent(e.target.value)}>
                  <option value=""></option>
                  <option value="0">None</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="60">60</option>
                </select>
              </div>
              <div>
              <h1>Activity</h1>
                <select name="activity" 
                        onChange={e => setActivity(e.target.value)}>
                <option value=""></option>
                  <option value="Gym">Gym</option>
                  <option value="Biking">Biking</option>
                  <option value="Tennis">Tennis</option>
                  <option value="Soccer">Soccer</option>
                  <option value="BasketBall">Basket Ball</option>
                  <option value="NeighborHood">Neighbor Hood</option>
                  <option value="Family Outdoor">Family Activity</option>
                </select>
              </div>
              <div>
                <h1>Completed</h1>
                <h3> 
                  <input type="radio" value="Yes" name="completed" 
                          onChange={e => setCompleted(e.target.value)}/> Yes{'\u00A0'}
                  <input type="radio" value="No" name="completed" 
                          onChange={e => setCompleted(e.target.value)}/> No
                </h3>
              </div>
              <div>
                <h1>Notes </h1>
                <input type='test' name="notes"
                  rows={5}
                  cols={5}
                  onChange={e => setNotes(e.target.value)}
                  />
              </div>
              <div>
                <button type="submit">Send</button>
              </div>
          </form>
          <Outlet />
        </div>
      );
    } else {
        return (
          <div>
            {/* <img alt="Home" src={defaultprofilepic} /> */}
            <br />
            <h1>
              Please Sign In and try again
            </h1>
            <span>
              <a href="/login">Sign In</a>
            </span>
            <Outlet />
          </div>
        );
    }
}

