import {BrowserRouter as Router} from 'react-router-dom'
import './App.css';

import Routes from './routes';
import Layout from './layout/Layout';

function App() {
  return (
    <main className="wrapper">
        <Router>
          <Layout>
              <Routes />
          </Layout>
        </Router>
	  </main>

  );
}
  
export default App;